//recupero data di ieri
var todayTimeStamp = +new Date(); // Unix timestamp in milliseconds
var oneDayTimeStamp = 1000 * 60 * 60 * 24; // Milliseconds in a day
var diff = todayTimeStamp - oneDayTimeStamp;
var yesterdayDate = new Date(diff);
export var yesterdayString =
  yesterdayDate.getFullYear() +
  "-" +
  ((yesterdayDate.getMonth() + 1 > 9 ? "" : "0") +
    (yesterdayDate.getMonth() + 1)) +
  "-" +
  ((yesterdayDate.getDate() > 9 ? "" : "0") + yesterdayDate.getDate());
